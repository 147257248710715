// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galerie-js": () => import("./../../../src/pages/galerie.js" /* webpackChunkName: "component---src-pages-galerie-js" */),
  "component---src-pages-hotove-projekty-js": () => import("./../../../src/pages/hotove-projekty.js" /* webpackChunkName: "component---src-pages-hotove-projekty-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-parcely-js": () => import("./../../../src/pages/parcely.js" /* webpackChunkName: "component---src-pages-parcely-js" */),
  "component---src-pages-vystavba-domu-js": () => import("./../../../src/pages/vystavba-domu.js" /* webpackChunkName: "component---src-pages-vystavba-domu-js" */),
  "component---src-templates-house-type-js": () => import("./../../../src/templates/house-type.js" /* webpackChunkName: "component---src-templates-house-type-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

